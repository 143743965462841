import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import {
  Home,
  SubmitSticker,
  ThankYouSubmit,
  Error,
  Contact,
  About,
  Stickers,
} from "../pages";

import { AnimatePresence } from "framer-motion";

const AnimatedRoutes = () => {
  const location = useLocation();
  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route exact path="/" element={<Home />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/submit" element={<SubmitSticker />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/thankyou" element={<ThankYouSubmit />}></Route>
        <Route exact path="/stickers" element={<Stickers />}></Route>
        <Route path="*" element={<Error />}></Route>
      </Routes>
    </AnimatePresence>
  );
};

export default AnimatedRoutes;
