import { useState } from "react";
import styled from "styled-components";
import Product from "./Sticker";

const GridView = ({ products }) => {
  const [paginate, setPaginate] = useState(30);

  const loadMore = (event) => {
    setPaginate((prevValue) => prevValue + 30);
  };

  // 10/20/2023 Used part of this code to hide the Load More button when reached the end of the array of stickers
  //stackoverflow.com/questions/44496352/reactjs-how-to-hide-load-more-option-after-all-data-fetch-from-database?rq=3

  return (
    <Wrapper>
      <div className="products-container">
        {products.slice(0, paginate).map((product) => {
          return <Product key={product.id} {...product} />;
        })}
      </div>
      {products.length > 30 && paginate < products.length ? (
        <button className="load-btn" onClick={loadMore}>
          Load More{" "}
        </button>
      ) : null}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  img {
    height: 175px;
  }

  .load-btn {
    text-transform: uppercase;
    background: #fb9101;
    color: var(--clr-primary-10);
    padding: 0.375rem 0.75rem;
    letter-spacing: var(--spacing);
    display: inline-block;
    font-weight: 400;
    transition: var(--transition);
    font-size: 0.875rem;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: var(--radius);
    border-color: transparent;
    margin-top: 2rem;
  }
  .load-btn:hover {
    color: var(--clr-primary-1);
    background: var(--clr-primary-7);
  }

  .products-container {
    display: grid;
    gap: 2rem 1.5rem;
  }

  @media (min-width: 992px) {
    .products-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (min-width: 1170px) {
    .products-container {
      grid-template-columns: repeat(3, 1fr);
    }
  }
`;

export default GridView;
