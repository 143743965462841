import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Navbar, Sidebar, Footer } from "./components";
import ScrollToTop from "./components/ScrollToTop";
import BackToTopBtn from "./components/BackToTopBtn";
import AnimatedRoutes from "./components/AnimatedRoutes";

import ReactGA from "react-ga";

function App() {
  useEffect(() => {
    ReactGA.initialize("UA-104854412-2");
    // To Report Page View
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      <Sidebar />
      <AnimatedRoutes />
      <BackToTopBtn />
      <Footer />
    </Router>
  );
}

export default App;
