import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { PageHero, Hero } from "../components";
import Banner from "../components/Banner";
import { motion, AnimatePresence } from "framer-motion";

const ThankYouSumit = () => {
  return (
    <AnimatePresence>
      <motion.main
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, ease: "linear" }}
      >
        <Hero hero="stickerSubmitHero">
          <Banner title="The Sonic Runway">
            <p className="photo-credit">Photo by: Danee Hazama</p>
          </Banner>
        </Hero>
        <PageHero title="Thank You" />
        <Wrapper>
          <div className="section-center">
            <h3>Thank you for submitting a sticker.</h3>
            <p>We Typically respond within 24 hours</p>

            <div className="content">
              <div>
                <div className="underline"></div>
                <div className="btn-container">
                  <Link to="/" className="home-contact thank-you-btn btn-hero">
                    Home
                  </Link>
                  <Link to="/submit" className="home-contact thank-you-btn">
                    Submit another sticker?
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Wrapper>
      </motion.main>
    </AnimatePresence>
  );
};
const Wrapper = styled.section`
  padding: 5rem 0;
  h3 {
    text-transform: none;
  }
  p {
    line-height: 2;
    max-width: 45em;
    color: #fb9101;
  }

  h3 {
    font-weight: 300;
  }

  h4.contact-confirm {
    color: #fb9101;
    font-weight: 200;
  }

  @media (min-width: 992px) {
    .content {
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      gap: 2rem;
      margin-top: 2rem;
    }
    p {
      margin-bottom: 0;
    }
  }
  // @media (min-width: 1280px) {
  //   padding: 15rem 0;
  // }

  .home-contact {
    display: grid;
    grid-template-columns: 40% auto;
  }

  a.home-contact.btn {
    margin: 0.5rem;
  }

  .underline {
    width: 100%;
    height: 0.125rem;
    background: #49a6e9;
    background: var(--clr-primary-5);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
  }

  .thank-you-btn {
    text-transform: uppercase;
    text-align: center;
    background: #fb9101;
    color: var(--clr-primary-10);
    padding: 0.375rem 0.75rem;
    letter-spacing: var(--spacing);
    display: inline-block;
    font-weight: 400;
    transition: var(--transition);
    font-size: 0.875rem;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: var(--radius);
    border-color: transparent;
    margin: 0.25rem;
  }
  .thank-you-btn:hover {
    color: var(--clr-primary-1);
    background: var(--clr-primary-7);
  }

  .btn-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
  }

  .btn-spacing {
  }
`;

export default ThankYouSumit;
