import React from "react";
import Banner from "../components/Banner";
import { Hero, FeaturedProducts, About } from "../components";
import { motion, AnimatePresence } from "framer-motion";

export default function home() {
  return (
    <AnimatePresence>
      <motion.main
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, ease: "linear" }}
      >
        <Hero>
          <Banner
            title="Burning Man Stickers"
            subtitle="A Catalog of BRC Stickers"
          >
            {/* <Link to="/submit" className="btn btn-hero">
            submit a sticker
          </Link> */}
            <p className="photo-credit">Photo By: Mark Nixon</p>
          </Banner>
        </Hero>

        <About />
        <FeaturedProducts />
      </motion.main>
    </AnimatePresence>
  );
}
