import React, { useState } from "react";
import styled from "styled-components";
import { FaTimes } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";
import ReactMarkdown from "react-markdown";
import { FaExternalLinkAlt } from "react-icons/fa";

const Product = ({
  id,
  image,
  url,
  name,
  year,
  BMTheme,
  officialtheme,
  createdby,
  creatorinfo,
  contributor,
  themecampname,
  camplink,
  artprojectname,
  artprojectlink,
  artcarname,
  artcarlink,
  official,
  desc,
  slug,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [activeObject, setActiveObject] = useState(null);

  function getClass(index) {
    return index === activeObject?.id ? "active" : "inactive";
  }

  // Function to render Creator info conditionally
  const renderCreatorInfo = () => {
    if (!createdby && !creatorinfo) {
      return null; // Return nothing if both are absent
    }
    return (
      <p className="info">
        <span>Creator: </span>
        <span className="sticker-year">
          {creatorinfo ? (
            <>
              <a href={creatorinfo} target="_blank" rel="noreferrer">
                {createdby}
                <span className="externallink">
                  <FaExternalLinkAlt />
                </span>
              </a>
            </>
          ) : (
            createdby
          )}
        </span>
      </p>
    );
  };

  // Function to render Art car info conditionally
  const renderArtCarInfo = () => {
    if (!artcarname && !artcarlink) {
      return null; // Return nothing if both are absent
    }
    return (
      <p className="info">
        <span>Art car: </span>
        <span className="sticker-year">
          {artcarlink ? (
            <>
              <a href={artcarlink} target="_blank" rel="noreferrer">
                {artcarname}
                <span className="externallink">
                  <FaExternalLinkAlt />
                </span>
              </a>
            </>
          ) : (
            artcarname
          )}
        </span>
      </p>
    );
  };

  // Function to render Theme camp info conditionally
  const renderThemeCampInfo = () => {
    if (!themecampname && !camplink) {
      return null; // Return nothing if both are absent
    }
    return (
      <p className="info">
        <span>Theme camp: </span>
        <span className="sticker-year">
          {camplink ? (
            <>
              <a href={camplink} target="_blank" rel="noreferrer">
                {themecampname}
                <span className="externallink">
                  <FaExternalLinkAlt />
                </span>
              </a>
            </>
          ) : (
            themecampname
          )}
        </span>
      </p>
    );
  };

  // Logic that displays the product details
  const Modal = () => (
    <main>
      <Wrapper>
        <div className="section page">
          <div
            className={showModal ? "modal-overlay show-modal" : "modal-overlay"}
          >
            <div id="productModal" className="modal-active modal-container">
              <div className="section section-center">
                <div className="product-center">
                  <img className="modal-image" src={url} alt={name} />
                  <section className="content">
                    <h2>{name}</h2>
                    <p className="info">
                      <span>Year: </span>
                      <span className="sticker-year">{year}</span>
                    </p>

                    {BMTheme && (
                      <p className="info">
                        <span>Theme: </span>
                        <span className="sticker-year">
                          <a href={BMTheme} target="_blank" rel="noreferrer">
                            {officialtheme}
                            <span className="externallink">
                              <FaExternalLinkAlt />
                            </span>
                          </a>
                        </span>
                      </p>
                    )}

                    {renderCreatorInfo()}

                    {contributor && (
                      <p className="info">
                        <span>Contributor: </span>
                        <span className="sticker-year">{contributor}</span>
                      </p>
                    )}

                    {renderThemeCampInfo()}

                    {artprojectlink && (
                      <p className="info">
                        <span>Art Project: </span>
                        <span className="sticker-year">
                          <a
                            href={artprojectlink}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {artprojectname}
                            <span className="externallink">
                              <FaExternalLinkAlt />
                            </span>
                          </a>
                        </span>
                      </p>
                    )}

                    {renderArtCarInfo()}

                    {official && (
                      <p className="info">
                        <span>"Official Sticker"</span> {official}
                      </p>
                    )}

                    {desc && (
                      <span className="info">
                        <span>Details: </span>
                        <ReactMarkdown className="sticker-desc">
                          {desc}
                        </ReactMarkdown>
                      </span>
                    )}

                    <button
                      className="close-modal-btn"
                      onClick={() => setShowModal(false)}
                    >
                      <FaTimes />
                    </button>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </main>
  );

  return (
    <Wrapper>
      {/* // The logic that selects the correct ID */}
      <ul className="list-menu container">
        <li
          key={id}
          onClick={() => {
            setActiveObject(`/stickers/${id}`);
            setShowModal(true);
          }}
          className={getClass(id)}
        >
          <img className="thumb" src={url} alt={name} />
          <span className="link">
            <FaSearch />
          </span>
          <footer>
            <h5>{name}</h5>
            <p>{year}</p>
          </footer>
        </li>
      </ul>
      {showModal ? <Modal Product={activeObject} /> : null}
    </Wrapper>
  );
};

const Wrapper = styled.article`
  .container {
    position: relative;
    // background: var(--clr-black);
    border-radius: var(--radius);
    padding: 10px;
    /* border: solid red 2px; */
  }

  /* Hides Details field is empty */
  .info:has(.sticker-desc:empty) {
    display: none;
  }

  .modal-container {
    position: relative;
    // background: var(--clr-black);
    border-radius: var(--radius);
    padding: 10px;
    border: solid #fff 2px;
    /* background-color: rgb(255,245,208) */
  }
  img {
    width: 100%;
    display: block;
    object-fit: cover;
    border-radius: var(--radius);
    transition: var(--transition);
  }

  /* img.thumb {
    height: 100%;
  } */

  img.modal-image {
    height: auto;
    max-width: 500px;
  }

  .link {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // background: var(--clr-primary-5);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    // border-radius: 50%;
    transition: var(--transition);
    opacity: 0;
    cursor: pointer;
    svg {
      font-size: 1.25rem;
      color: var(--clr-white);
    }
  }

  @media only screen and (min-width: 1025px) {
    .container:hover img {
      opacity: 0.5;
    }
    .container:hover .link {
      opacity: 1;
    }
  }
  @media only screen and (min-width: 1025px) {
    .modal-container:hover img {
      pointer-events: none;
    }
    .modal-container:hover .link {
      opacity: 1;
    }
  }

  footer {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.25rem;
  }

  footer h5 {
    color: #fb9101;
  }

  footer h5,
  footer p {
    margin-bottom: 0;
    font-weight: 400;
  }

  footer p {
    color: var(--clr-primary-5);
    letter-spacing: var(--spacing);
  }

  .main {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--grey-100);
  }
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: grid;
    place-items: center;
    z-index: -1;
    visibility: hidden;
    opacity: 0;
  }
  /* OPEN/CLOSE MODAL */
  .show-modal {
    opacity: 1;
    transition-property: opacity;
    transition-duration: 2s;
    visibility: visible;
    z-index: 25;
  }

  .modal-active {
    /* background: rgb(255, 241, 208); */
    height: 85vh;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 10;
    /* border-radius: 10px;
    width: 95vw;
    max-width: 1120px;
    text-align: center;
    display: grid;
    place-items: center;
    position: relative; */
  }

  li.inactive {
    /* max-width: 300px; */
    /* height: 100%; */
  }

  .close-modal-btn {
    /* position: absolute; */
    position: fixed;
    top: 4rem;
    right: 1rem;
    font-size: 2rem;
    background: transparent;
    border-color: transparent;
    color: red;
    cursor: pointer;
  }

  @media screen and (min-width: 992px) {
    .product-center {
      display: grid;
      gap: 4rem;
      margin-top: 0.25rem;
    }
  }
  .year {
    color: var(--clr-primary-5);
  }

  span.sticker-year {
    color: #696969;
    font-weight: 400 !important;
  }
  span.sticker-desc {
    color: #696969;
    font-weight: 400 !important;
  }

  .creator {
    font-weight: 400 !important;
    color: #fb9107;
  }

  @media screen and (min-width: 992px) {
    .product-center {
      grid-template-columns: 1fr 1fr;
      align-items: center;
    }
  }

  .info {
    text-transform: capitalize;
    /* width: 450px; */
    display: grid;
    grid-template-columns: 150px 1fr;
    color: var(--clr-primary-5);
    span {
      font-weight: 700;
    }
  }
  .camp-info {
    text-transform: capitalize;
    /* width: 450px; */
    display: grid;
    grid-template-columns: 150px 1fr;
    color: #fb9101;
    font-weight: 400;
  }

  .sticker-details p {
    color: #696969;
    font-weight: 400 !important;
  }

  .main {
    height: 600px;
  }
  .modal-img {
    width: 100%;
    height: auto !important;
    display: block;
    border-radius: var(--radius);
    object-fit: cover;
  }

  span.externallink {
    /* color: #36454f; */
    margin: 0.5rem;
    padding: 0.2rem;
    font-size: 0.75rem;
  }
`;
export default Product;
