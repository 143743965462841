import React from "react";
import { GiCompass, GiDiamondHard, GiStabbedNote } from "react-icons/gi";
export const links = [
  {
    id: 1,
    text: "stickers",
    url: "/stickers",
  },
  {
    id: 2,
    text: "Submit a sticker",
    url: "/submit",
  },

  {
    id: 3,
    text: "Contact",
    url: "/contact",
  },
];

export const about = [
  {
    id: 1,
    icon: <GiCompass />,
    title: "mission",
    text: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptates, ea. Perferendis corrupti reiciendis nesciunt rerum velit autem unde numquam nisi",
  },
  {
    id: 2,
    icon: <GiDiamondHard />,
    title: "vision",
    text: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptates, ea. Perferendis corrupti reiciendis nesciunt rerum velit autem unde numquam nisi",
  },
  {
    id: 3,
    icon: <GiStabbedNote />,
    title: "history",
    text: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptates, ea. Perferendis corrupti reiciendis nesciunt rerum velit autem unde numquam nisi",
  },
];

export const products_url =
  "https://www.burningmanstickers.org/.netlify/functions/airtable";

export const single_product_url = `https://www.burningmanstickers.org/.netlify/functions/airtable?id=`;

// export const products_url = "http://localhost:8888/.netlify/functions/airtable";

// export const single_product_url = "http://localhost:8888/.netlify/functions/airtable?id=";